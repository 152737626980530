import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faFacebook, faTwitter, faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import 'typeface-roboto'
import './App.scss'

import image_CharlesOmer from './Images/CharlesOmer.jpg'

const theme = createMuiTheme({
    palette: {
        type: 'dark',
    },
    typography: { useNextVariants: true },
});

class App extends Component {
  render() {
    return (
        <MuiThemeProvider theme={theme}>
            <div className="home-wrapper">
                <Grid container spacing={40} className="home-grid">
                    <Grid item xs={12}>
                        <Typography variant="h2">
                            Charles Omer
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Avatar className="profile-picture" alt="Charles Omer" src={image_CharlesOmer} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Computer Science
                        </Typography>
                        <Typography variant="caption">
                            <i>University of Southampton</i>
                        </Typography>
                    </Grid>

                    <Grid item container justify="center" alignItems="flex-end" spacing={16} className="social-container">
                        <Grid item xs={12} sm={4} md={2}>
                            <Button variant="outlined" href="https://www.linkedin.com/in/charles-omer" target="_blank" rel="noopener">
                                <FontAwesomeIcon icon={faLinkedinIn} /> LinkedIn
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Button variant="outlined" href="https://www.facebook.com/charlesomerofficial" target="_blank" rel="noopener">
                                <FontAwesomeIcon icon={faFacebook} /> Facebook
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Button variant="outlined" href="https://www.twitter.com/charles_omer" target="_blank" rel="noopener">
                                <FontAwesomeIcon icon={faTwitter} /> Twitter
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Button variant="outlined" href="https://www.github.com/charlesomer" target="_blank" rel="noopener">
                                <FontAwesomeIcon icon={faGithub} /> GitHub
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Button variant="outlined" href="https://www.instagram.com/charles_omer" target="_blank" rel="noopener">
                                <FontAwesomeIcon icon={faInstagram} /> Instagram
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Button variant="outlined" href="mailto:contact@zyndex.co.uk" target="_blank" rel="noopener">
                                <FontAwesomeIcon icon={faEnvelope} /> Email
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <footer>
                <Grid container spacing={40} className="footer-grid">
                    <Grid item xs={12}>
                        <Button href="https://www.charlesomer.co.uk" target="_blank" rel="noopener">
                            www.charlesomer.co.uk
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button href="https://www.spacexdata.info" target="_blank" rel="noopener">
                            www.spacexdata.info
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button href="https://www.zyndex.co.uk" target="_blank" rel="noopener">
                            www.zyndex.co.uk
                        </Button>
                    </Grid>
                </Grid>
            </footer>
        </MuiThemeProvider>
    );
  }
}

export default App;
